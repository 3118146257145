/* ./styles/global.css */
@tailwind base;
@tailwind components;

@layer base {
	/* Custom reset */
	html {
		@apply bg-black;
		font: 100%/1.4 Inter, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
			Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
			"Segoe UI Symbol";
		scroll-behavior: smooth;
		-webkit-font-smoothing: antialiased;
		scroll-padding: 150px 0 0 0;
	}

	strong {
		@apply text-white font-bold;
	}
	.nav-link-active {
		@apply relative;
	}
	.nav-link-active:before {
		@apply absolute -bottom-2 left-0 right-0 h-0.5 bg-gray-500 rounded-full;
		content: "";
	}

	model-viewer {
		@apply w-full h-full;
		--poster-color: transparent;
		--progress-bar-color: rgba(255, 255, 255, 0.8);
	}

	.global-link:before {
		@apply absolute z-0 inset-0;
		content: "";
	}
}

.web3modal-modal-lightbox {
	z-index: 9999 !important;
}
.react-toast-notifications__container {
	padding: 0 !important;
}

@tailwind utilities;
